<template>
  <div class="flex flex-col min-h-screen items-center bg-brand-light-sand space-y-10 px-5 py-12">
    <div class="w-full max-w-xl flex-grow flex flex-col justify-center items-center space-y-10">
      <!-- <img v-if="companyLogo" :src="companyLogo" class="max-w-12 max-h-12" /> -->
      <BrandImage :src="companyLogo" alignment="center" v-if="companyLogo" />
      <div v-if="!showStripeForm && !showSuccessScreen">
        <!-- WITH DISCOUNT -->
        <div v-if="hasDiscount" class="bg-white rounded-lg border-2 border-brand-sand p-8 text-center">
          <div class="mt-5 text-lg text-brand-black font-bold">Confirm your special offer below.</div>
          <p class="mt-5 text-brand-brown text-opacity-90 text-md font-medium leading-relaxed">
            Get a {{ discount.coupon | formatCouponAmount }} discount {{ discount.coupon | formatCouponDuration('for') }} by reactivating your subscription
            today ({{ amountAfterDiscount | formatAmount }} {{ price | formatInterval }} {{ discount.coupon | formatCouponDuration('for') }}).
          </p>
          <button
            class="mt-6 w-full p-3 rounded-md bg-brand-blue text-white font-bold disabled:opacity-70 disabled:cursor-default"
            @click="reactivate"
            :disabled="reactivationLoading"
          >
            <span v-if="reactivationLoading"><Loader /> Processing...</span>
            <span v-else>Confirm Offer</span>
          </button>
        </div>
        <!-- WITHOUT DISCOUNT -->
        <div v-else class="bg-white rounded-lg border-2 border-brand-sand p-8 text-center">
          <div class="mt-5 text-lg text-brand-black font-bold">We missed you!</div>
          <p class="mt-5 text-brand-brown text-opacity-90 text-md font-medium leading-relaxed">
            We'd love to have you back! Reactivate your subscription today for only {{ amountBeforeDiscount | formatAmount }} {{ price | formatInterval }}.
          </p>
          <button
            class="mt-6 w-full p-3 rounded-md bg-brand-blue text-white font-bold disabled:opacity-70 disabled:cursor-default"
            @click="reactivate"
            :disabled="reactivationLoading"
          >
            <span v-if="reactivationLoading"><Loader /> Processing...</span>
            <span v-else>Reactivate</span>
          </button>
        </div>
        <p v-if="hasDiscount" class="text-brand-brown text-xs p-2 text-center leading-relaxed">
          When you reactivate your account, you’ll regain complete access at a discounted {{ amountAfterDiscount | formatAmount }} {{ price | formatInterval }}
          {{ discount.coupon | formatCouponDuration('for') }}, then {{ amountBeforeDiscount | formatAmount }} {{ price | formatInterval }} thereafter.
        </p>
        <p v-else class="text-brand-brown text-xs p-2 text-center leading-relaxed">
          When you reactivate your account, you’ll regain complete access for {{ amountBeforeDiscount | formatAmount }} {{ price | formatInterval }}.
        </p>
      </div>
      <div v-if="showStripeForm">
        <ReactivationStripeForm class="px-0 pt-0 md:bg-brand-light-sand" />
      </div>
      <div v-if="showSuccessScreen">
        <div class="bg-white rounded-lg border-2 border-brand-sand p-8 text-center">
          <div class="mt-5 text-lg text-brand-black font-bold">Done! We're so happy to have you back.</div>
          <p class="mt-5 text-brand-brown text-opacity-90 text-md font-medium leading-relaxed" v-if="hasDiscount">
            We just applied your {{ discount.coupon | formatCouponAmount }} discount {{ discount.coupon | formatCouponDuration('for') }} to your subscription.
            On behalf of the {{ companyName }} team, thank you!
          </p>
          <p v-else>On behalf of the {{ companyName }} team, thank you!</p>
          <button class="mt-6 w-full p-3 rounded-md bg-brand-blue text-white font-bold disabled:opacity-70 disabled:cursor-default" @click="redirect">
            <span>Continue to {{ companyName }}</span>
          </button>
        </div>
        <p v-if="hasDiscount" class="text-brand-brown text-xs p-2 text-center leading-relaxed">
          Your card on file will be charged {{ amountAfterDiscount | formatAmount }} {{ price | formatInterval }}
          {{ discount.coupon | formatCouponDuration('for') }}, then {{ amountBeforeDiscount | formatAmount }} {{ price | formatInterval }} thereafter.
        </p>
        <p v-else class="text-brand-brown text-xs p-2 text-center leading-relaxed">
          Your card on file will be charged {{ amountBeforeDiscount | formatAmount }} {{ price | formatInterval }}.
        </p>
      </div>
    </div>
    <div class="flex-none">
      <div v-if="showWatermark">
        <Watermark message="Offer Guaranteed by Churnkey" product="re" />
      </div>
      <div v-else>
        <p class="text-brand-brown text-sm font-medium">
          Questions?
          <a :href="redirectLink" target="_blank">Contact Us.</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import EventBus from '@/helpers/event-bus';
import * as Filters from '@/helpers/filters';
import ReactivationStripeForm from '@/components/ReactivationStripeForm.vue';
import Loader from '@/components/ButtonLoader.vue';
import OrgService from '@/api/org.service';
import { getIntegerCurrencyString } from '@/helpers/currency-utils';
import Watermark from '@/components/Watermark.vue';
import BrandImage from '@/components/BrandImage.vue';

dayjs.extend(relativeTime);

const demoDiscount = {
  success: true,
  subscriptionId: 'sub_FFF',
  canceledSubscription: {
    id: 'sub_FFF',
    object: 'subscription',
    application: 'ca_FFF',
    application_fee_percent: null,
    automatic_tax: {
      enabled: false,
    },
    billing_cycle_anchor: 1704385234,
    billing_thresholds: null,
    cancel_at: null,
    cancel_at_period_end: false,
    canceled_at: 1704385238,
    cancellation_details: {
      comment: null,
      feedback: null,
      reason: 'cancellation_requested',
    },
    collection_method: 'charge_automatically',
    created: 1704385234,
    currency: 'usd',
    current_period_end: 1707063634,
    current_period_start: 1704385234,
    customer: 'cus_FFF',
    days_until_due: null,
    default_payment_method: 'pm_FFF',
    default_source: null,
    default_tax_rates: [],
    description: null,
    discount: null,
    ended_at: 1704385238,
    items: {
      object: 'list',
      data: [
        {
          id: 'si_FFF',
          object: 'subscription_item',
          billing_thresholds: null,
          created: 1704385234,
          metadata: {},
          plan: {
            id: 'price_FFF',
            object: 'plan',
            active: true,
            aggregate_usage: null,
            amount: 9900,
            amount_decimal: '9900',
            billing_scheme: 'per_unit',
            created: 1687410459,
            currency: 'usd',
            interval: 'month',
            interval_count: 1,
            livemode: false,
            metadata: {},
            nickname: null,
            product: 'prod_FFF',
            tiers_mode: null,
            transform_usage: null,
            trial_period_days: null,
            usage_type: 'licensed',
          },
          price: {
            id: 'price_FFF',
            object: 'price',
            active: true,
            billing_scheme: 'per_unit',
            created: 1687410459,
            currency: 'usd',
            custom_unit_amount: null,
            livemode: false,
            lookup_key: null,
            metadata: {},
            nickname: null,
            product: 'prod_FFF',
            recurring: {
              aggregate_usage: null,
              interval: 'month',
              interval_count: 1,
              trial_period_days: null,
              usage_type: 'licensed',
            },
            tax_behavior: 'unspecified',
            tiers_mode: null,
            transform_quantity: null,
            type: 'recurring',
            unit_amount: 9900,
            unit_amount_decimal: '9900',
          },
          quantity: 1,
          subscription: 'sub_FFF',
          tax_rates: [],
        },
      ],
      has_more: false,
      total_count: 1,
      url: '/v1/subscription_items?subscription=sub_FFF',
    },
    latest_invoice: 'in_FFF',
    livemode: false,
    metadata: {},
    next_pending_invoice_item_invoice: null,
    on_behalf_of: null,
    pause_collection: null,
    payment_settings: {
      payment_method_options: null,
      payment_method_types: null,
      save_default_payment_method: 'off',
    },
    pending_invoice_item_interval: null,
    pending_setup_intent: null,
    pending_update: null,
    plan: {
      id: 'price_FFF',
      object: 'plan',
      active: true,
      aggregate_usage: null,
      amount: 9900,
      amount_decimal: '9900',
      billing_scheme: 'per_unit',
      created: 1687410459,
      currency: 'usd',
      interval: 'month',
      interval_count: 1,
      livemode: false,
      metadata: {},
      nickname: null,
      product: 'prod_FFF',
      tiers_mode: null,
      transform_usage: null,
      trial_period_days: null,
      usage_type: 'licensed',
    },
    quantity: 1,
    schedule: null,
    start_date: 1704385234,
    status: 'canceled',
    test_clock: null,
    transfer_data: null,
    trial_end: null,
    trial_settings: {
      end_behavior: {
        missing_payment_method: 'create_invoice',
      },
    },
    trial_start: null,
  },
  canceledSubscriptionItems: [
    {
      id: 'si_FFF',
      object: 'subscription_item',
      billing_thresholds: null,
      created: 1704385234,
      metadata: {},
      plan: {
        id: 'price_FFF',
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 9900,
        amount_decimal: '9900',
        billing_scheme: 'per_unit',
        created: 1687410459,
        currency: 'usd',
        interval: 'month',
        interval_count: 1,
        livemode: false,
        metadata: {},
        nickname: null,
        product: 'prod_FFF',
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed',
      },
      price: {
        id: 'price_FFF',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1687410459,
        currency: 'usd',
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_FFFF',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          trial_period_days: null,
          usage_type: 'licensed',
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900',
      },
      quantity: 1,
      subscription: 'sub_FFF',
      tax_rates: [],
    },
  ],
  amountBeforeDiscount: 9900,
  amountAfterDiscount: 7920,
  coupon: {
    id: 'JEyb9GbJ',
    couponType: 'PERCENT',
    couponAmount: 20,
    currency: null,
    couponDuration: 1,
  },
};

const demoCanceledSub = {
  orgId: 'DEMO_ORG',
  subId: 'sub_FFF',
  __v: 0,
  canceled_at: '2024-01-04T16:20:38.000Z',
  created: '2024-01-04T16:20:34.000Z',
  currency: 'usd',
  current_period_end: '2024-02-04T16:20:34.000Z',
  current_period_start: '2024-01-04T16:20:34.000Z',
  cust_discount: null,
  customer: 'cus_PJWi7h1fYPoFqU',
  dbCreatedAt: '2024-01-04T16:20:39.738Z',
  dbUpdatedAt: '2024-01-04T16:20:39.940Z',
  email: null,
  ended_at: '2024-01-04T16:20:38.000Z',
  orgName: 'Demo Org',
  paymentProvider: 'stripe',
  plan: {
    id: 'price_FFF',
    nickname: null,
    active: true,
    amount: 9900,
    interval: 'month',
    tiers: null,
  },
  start_date: '2024-01-04T16:20:34.000Z',
  status: 'canceled',
  sub_discount: null,
  subscription_items: [
    {
      id: 'si_PJWioY7bSlMGT8',
      quantity: 1,
      price: {
        priceId: 'price_FFF',
        productId: 'prod_FFF',
        nickname: null,
        active: true,
        billingSchema: 'per_unit',
        amount: 9900,
        interval: 'month',
        intervalCount: 1,
        type: 'recurring',
      },
    },
  ],
};

export default {
  name: 'reactivate',
  components: {
    ReactivationStripeForm,
    Loader,
    Watermark,
    BrandImage,
  },
  data() {
    return {
      submitted: false,
      reactivationLoading: false,
      reactivationSuccess: null,
    };
  },
  async created() {
    await this.$store.dispatch('FETCH_CONTEXT');
  },
  mounted() {
    this.$i18n.locale = navigator.language;
    EventBus.$on('alert', (title, msg) => this.launchAlert(title, msg));
    EventBus.$on('reactivated', (data) => this.subReactivated(data));
  },
  computed: {
    showStripeForm() {
      return false;
    },
    showSuccessScreen() {
      return this.submitted && this.reactivationSuccess;
    },
    showWatermark() {
      const orgShowsWatermark = this.account?.branding?.showWatermark;
      return orgShowsWatermark && this.showSuccessScreen;
    },
    account() {
      const acc = this.$store.state?.account;
      return {
        ...acc,
        dunning: {
          ...acc.dunning,
          displayDiscount: demoDiscount,
        },
      };
    },
    discount() {
      const discount = this.account?.dunning?.displayDiscount;
      if (!discount) return null;
      const { coupon, ...theRest } = discount;
      const currency = this.currency; // eslint-disable-line
      const updatedDiscount = {
        coupon: {
          ...coupon,
          currency,
        },
        ...theRest,
      };
      return updatedDiscount;
    },
    amountBeforeDiscount() {
      return this.discount?.amountBeforeDiscount;
    },
    amountAfterDiscount() {
      return this.discount?.amountAfterDiscount;
    },
    hasDiscount() {
      return !!this.account?.dunning?.displayDiscount?.coupon;
    },
    customerName() {
      return this.account?.name || this.account?.email;
    },
    defaultPaymentMethod() {
      return this.account?.defaultPaymentMethod || this.account?.defaultSource;
    },
    hasDefaultPaymentMethod() {
      return !!this.defaultPaymentMethod;
    },
    defaultPaymentMethodIsCard() {
      return this.defaultPaymentMethod?.type === 'card';
    },
    cardLast4() {
      return this.defaultPaymentMethod?.card?.last4 || null;
    },
    cardBrand() {
      return this.defaultPaymentMethod?.card?.brand || null;
    },
    canceledSubscription() {
      return demoCanceledSub; // this.account.dunning.subscription;
    },
    currency() {
      if (!this.canceledSubscription) return null;
      return this.canceledSubscription.currency;
    },
    items() {
      if (this.canceledSubscription) {
        return this.canceledSubscription.subscription_items;
      }
      return null;
    },
    item() {
      let item;
      if (this.items && Array.isArray(this.items) && this.items.length > 0) {
        item = this.items[0];
        return item;
      }
      return null;
    },
    price() {
      if (this.item?.price) {
        return this.item.price;
      }
      return null;
    },
    companyName() {
      return this.account?.name || this.account?.orgName;
    },
    companyLogo() {
      return this.account.branding?.brandImage;
    },
    redirectLink() {
      let link = this.account?.dunning?.checkoutPage?.redirectLink;
      if (link && link.indexOf('://') === -1) {
        link = `https://${link}`;
      }
      return link;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    errorContext() {
      return this.$store.state.account;
    },
  },
  methods: {
    redirect() {
      // navigate to the org's website
      window.location.href = this.redirectLink;
    },
    async reactivate() {
      if (this.reactivationLoading) {
        console.log('Already processing!');
        return;
      }
      const { subId } = this.canceledSubscription;
      this.reactivationLoading = true;
      this.submitted = true;
      // const data = await OrgService.reactivate({ subscriptionId: subId });

      // delay for demo purposes and mock a data result
      const data = await new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            success: true,
          });
        }, 2000);
      });
      this.subReactivated(data);
    },
    // the function below is called from here if the 1-click reactivation is used, or through the EvenBus if from Stripe Elements
    async subReactivated(data) {
      this.submitted = true;
      this.reactivationLoading = false;
      this.reactivationSuccess = !!data?.success; // computed properties read this to decide what to show next
    },
  },
  filters: {
    formatInterval(price) {
      try {
        const { interval, intervalCount } = price;
        const separator = intervalCount === 1 ? 'per' : 'every';
        return `${separator} ${interval}`;
      } catch (err) {
        // throw err;
        console.log('Error parsing price!', err.message);
      }
    },
    formatAmount(amount, currency) {
      return getIntegerCurrencyString(amount, currency, false);
    },
    formatCouponDuration: Filters.formatCouponDuration,
    formatCouponAmount: Filters.formatCouponAmount,
  },
};
</script>

<style lang="scss">
@import '@/assets/style.scss';
</style>
